import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import DateTimeDisplay from './DateTimeDisplay';
import Countdown from "react-countdown";
import moment from "moment/moment";
import { useAccount, useWalletClient } from "wagmi";
import Web3 from "web3/dist/web3.min.js";
import { ethers } from 'ethers';
import Swal from "sweetalert2";
import Spinner from "react-bootstrap/Spinner";
import StakeNFTContractABI from "../contractABI/NftStakeAbi.json";
import NFTContractABI from "../contractABI/NftAbi.json";

const contractAddress = process.env.REACT_APP_ELMO_NFT_ADDRESS;
const contractStaking = process.env.REACT_APP_ELMO_NFT_STAKING;
const infuraURL = process.env.REACT_APP_INFURA_URL;

const StakeNftForm = () => {
  const { data: signer } = useWalletClient();
  const { address, isConnected } = useAccount();
  const [provider, setProvider] = useState("");
  const [totalBurnedByAll, setTotalBurnedByAll] = useState(0);
  const [totalStakedNTFs, setTotalStakedNTFs] = useState(0);
  const [inStakingPool, setInStakingPool] = useState(0);
  const [nfts, setNfts] = useState([]);
  const [stakePeriod, setStakePeriod] = useState(0);
  const [countDownCompleted, setCountDownCompleted] = useState(false);
  const [stakedStatuses, setStakedStatuses] = useState({});
  const [selectedBurnPercents, setSelectedBurnPercents] = useState({});
  const [rewardsAmount, setRewardsAmount] = useState(0);

  const [mintLoading, setMintLoading] = useState(false);
  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const showAlert = (title, text, icon) => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showConfirmButton: false,
        background: 'rgba(3, 3, 17, .95)',
        color: '#FFFFFF',
        backdrop: `rgba(3, 3, 17, .65)`,
        timer: 6500
      });
  }

  const showLoading = (title) => {
    Swal.fire({
        title: title,
        timerProgressBar: true,
        showConfirmButton: false,
        background: 'rgba(3, 3, 17, .95)',
        color: '#FFFFFF',
        backdrop: `rgba(3, 3, 17, .65)`,
        didOpen: () => {
          Swal.showLoading()
        },
      });
  }

  useEffect(() => {
    fetchSupplyData();
  }, []);

  useEffect(() => {
    if (signer) {
      setProvider(signer);
    }
  }, [signer, isConnected]);

  ///Fetch supply
  const fetchSupplyData = async () => {
    let web3;
    if(provider) {
      web3 = new Web3(provider);
    } else {
      web3 = new Web3(new Web3.providers.WebsocketProvider(infuraURL));
    }
    const contract = new web3.eth.Contract(NFTContractABI, contractAddress);
    const nftStakeContract = new web3.eth.Contract(StakeNFTContractABI, contractStaking);

    const totalSupplyFromContract = await contract.methods.totalSupply().call();
    const maxSupplyFromContract = await contract.methods.MAX_SUPPLY().call();
    const totalBurnedAll = await nftStakeContract.methods.totalBurnedByAll().call();
    const stakingPool = await nftStakeContract.methods.stakingPool().call();
    const totalStaked = await nftStakeContract.methods.getTotalStakedNFTCount().call();
    const periodToStake = await nftStakeContract.methods.STAKE_PERIOD().call();
    const amountOfRewards = await nftStakeContract.methods._rewardAmount().call();

    setStakePeriod(periodToStake);

    setTotalSupply(totalSupplyFromContract);
    setMaxSupply(maxSupplyFromContract);
    setTotalBurnedByAll(totalBurnedAll);
    setInStakingPool(stakingPool);
    setTotalStakedNTFs(totalStaked);
    setRewardsAmount(amountOfRewards);
  };

  const fetchStakedStatuses = async (myNfts) => {
    const statuses = {};
    let web3;
    if(provider) {
      web3 = new Web3(provider);
    } else {
      web3 = new Web3(new Web3.providers.WebsocketProvider(infuraURL));
    }
    const nftStakeContract = new web3.eth.Contract(StakeNFTContractABI, contractStaking);
    for (let token of myNfts) {
      statuses[token.id] = await nftStakeContract.methods.stakedStatus(token.id).call();
    }
    setStakedStatuses(statuses);
  }

  const stakeNFT = async (tokenId, burnPercent) => {
    try {
      const web3 = new Web3(provider);
      window.NFTStaking = new web3.eth.Contract(
        StakeNFTContractABI, 
        contractStaking
      );

      if (address === "" || address === undefined || address == null) {
        showAlert('Error', 'Please connect wallet!', 'error')
        return;
      }

      if(burnPercent === "" || burnPercent === undefined || burnPercent == null) {
        showAlert('Error', 'Please select a burning rate!', 'error')
        return;
      }

      setMintLoading(true);
      showLoading('Staking NFT #' + tokenId)
      await window.NFTStaking.methods
        .stakeNFT(tokenId, burnPercent)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'You staked NFT #' + tokenId + '!', 'success')
            setMintLoading(false);
            fetchSupplyData();
            fetchNFTs();
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
            setMintLoading(false);
          } else {
            showAlert('Error', error, 'error')
            setMintLoading(false);
          }
        });
    } catch (err) {
      console.log(err);
      showAlert('Error', err, 'error')
      setMintLoading(false);
    }
  };

  const unstakeNFT = async (tokenId) => {
    try {
      const web3 = new Web3(provider);
      window.NFTStaking = new web3.eth.Contract(
        StakeNFTContractABI, 
        contractStaking
      );

      if (address === "" || address === undefined || address == null) {
        showAlert('Error', 'Please connect wallet!', 'error')
        return;
      }

      setMintLoading(true);
      showLoading('Claiming for NFT #' + tokenId)
      await window.NFTStaking.methods
        .unstakeNFT(tokenId)
        .send({ from: address })
        .on("transactionHash", (hash) => {
          console.log('Transaction hash:', hash);
        })
        .on('confirmation', async (confirmationNumber, receipt) => {
          if (confirmationNumber === 1 && receipt.status) {
            Swal.close()
            showAlert('Success', 'Claim for NFT #' + tokenId + ' is completed!', 'success')
            setMintLoading(false);
            fetchSupplyData();
            fetchNFTs();
          }
        })
        .on("error", (error) => {
          console.log(error)
          Swal.close()
          if (error.code === 4001) {
            showAlert('Error', 'User rejected transcation', 'error')
            setMintLoading(false);
          } else {
            showAlert('Error', error, 'error')
            setMintLoading(false);
          }
        });
    } catch (err) {
      console.log(err);
      showAlert('Error', err, 'error')
      setMintLoading(false);
    }
  };

  const fetchNFTs = async () => {
    setIsLoading(true);
    try {
      if ((!provider && !signer) || !isConnected) {
        console.log("Wallet not connected.");
        return;
      }
      
      const ownedNFTs = await getOwnedNFTs();
      const stakedNFTInfos = await getStakedTokensAndInfo();

      // Start with the owned NFTs
      let combinedNFTs = ownedNFTs.map(nft => ({
        ...nft,
        staked: !!stakedNFTInfos.find(info => info.tokenId === nft.id),
        stakeInfo: stakedNFTInfos.find(info => info.tokenId === nft.id)?.stakeInfo || null,
      }));

      for (const stakedNFT of stakedNFTInfos) {
        if (!combinedNFTs.find(nft => nft.id === stakedNFT.tokenId)) {
            const metadata = await getMetadataForToken(stakedNFT.tokenId, 'https://ipfs.io/ipfs/bafybeiagzelassfkopzzym7rd242zfd4plp5uzgj5hawviepeedlyctz7e/');
            combinedNFTs.push({
                id: stakedNFT.tokenId,
                metadata: metadata,
                staked: true,
                stakeInfo: stakedNFT.stakeInfo,
            });
        }
    }

      setNfts(combinedNFTs);
      fetchStakedStatuses(combinedNFTs);
      
    } catch (error) {
      console.error("Error fetching NFTs:", error);
      showAlert('Error', 'An error occurred while fetching NFTs.', 'error');
    } finally {
      setIsLoading(false); // Ensuring that the loading state is always reset, whether there's an error or not.
    }
  };

  const getMetadataForToken = async (tokenId, tokenURI) => {
    try {
        const response = await fetch(tokenURI + tokenId + '.json');
        return await response.json();
    } catch (error) {
        console.error(`Failed to fetch metadata for token ${tokenId}`, error);
        return {}; // Return an empty object or some default metadata if fetching fails
    }
}

  const getStakedTokensAndInfo = async () => {
    const web3 = new Web3(signer || provider);
    const contract = new web3.eth.Contract(StakeNFTContractABI, contractStaking);

    const stakedTokens = await getStakedTokensByUser();
    const stakeInfos = await Promise.all(stakedTokens.map(async (tokenId) => {
      const stakeInfo = await contract.methods.stakes(address, tokenId).call();
      return {
        tokenId,
        stakeInfo,
      };
    }));
    return stakeInfos;
  };

  const getStakedTokensByUser = async () => {
    const web3 = new Web3(signer || provider);
    const contract = new web3.eth.Contract(StakeNFTContractABI, contractStaking);
    const stakedTokenCount = await contract.methods.getStakedNFTCount(address).call();

    let stakedTokens = [];
    for (let i = 0; i < stakedTokenCount; i++) {
        let tokenId = await contract.methods._stakedTokensByUser(address, i).call();
        stakedTokens.push(tokenId);
    }

    return stakedTokens;
  };

  const getOwnedNFTs = async () => {
    const web3 = new Web3(signer || provider);
    const contract = new web3.eth.Contract(NFTContractABI, contractAddress);
  
    const balance = await contract.methods.balanceOf(address).call();
    let tokens = [];
    for (let i = 0; i < balance; i++) {
      const tokenID = await contract.methods.tokenOfOwnerByIndex(address, i).call();
      /////const tokenURI = await contract.methods.tokenURI(tokenID).call();
      const metadata = await getMetadataForToken(tokenID, 'https://ipfs.io/ipfs/bafybeiagzelassfkopzzym7rd242zfd4plp5uzgj5hawviepeedlyctz7e/');
      tokens.push({
        id: tokenID,
        metadata: metadata
      });
    }
    return tokens;
  }

  const ipfsUrl = (url) => {
    return url.replace('ipfs://', 'https://ipfs.io/ipfs/');
  }
  
  useEffect(() => {
    fetchNFTs();
  }, [provider, signer, isConnected]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state when finished
      return (
        <>
        <p className='mb-0'>You can now withdraw your $ELMO rewards for this NFT!</p>
        </>
      );
    } else {
      // Render a countdown
      return (
        <>
        <p className='mb-0'>Claim in:</p>
        <div className="show-counter">
          <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 2} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
        </div>
        </>
      );
    }
  };

  const handleBurnPercentSelection = (tokenId, percent) => {
    setSelectedBurnPercents(prevState => ({
      ...prevState,
      [tokenId]: percent
    }));
  };

  return (
    <Row>
      <Col className='col-12 col-lg-12 btm'>
        <div className='minting__info'>
          <h2 className='title'>Stake NFTs</h2>
          <br />
          <p className='fontSmall'></p>
        </div>
        <div className='minting'>
          {mintLoading ? (
            <div className="loader-wrap">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <div className='minting__group'>
              <div className='general-info'>
                <span>Reward pool: {Number(ethers.formatUnits(inStakingPool, 18)).toLocaleString("en-US")} $ELMO</span>
                <br/>
                <span>Minted: {totalSupply}/{maxSupply} NFTs</span>
                <span>Total burned: {Number(ethers.formatUnits(totalBurnedByAll, 18)).toLocaleString("en-US")} $ELMO</span>
                <span>Currently staked: {Number(totalStakedNTFs)} {Number(totalStakedNTFs) > 1 ? ('NFTs') : ('NFT')}</span>
              </div>
              <br />
              <p>Stake your Elmofo NFT now and earn and burn $ELMO. You decide how much you burn; 40, 60 or 80% of (rewards) $ELMO. Choose wisely between greed and support!
                <br /><br />
              Each NFT can be staked individually so you can choose the burn percentage for each Elmofo NFT.
              <br /><br />
              Don't have an NFT? You can mint one through <a target='_blank' rel='noreferrer' href="https://nfts.elmoerc.io">nfts.elmoerc.io</a></p>
            </div>
          )}
        </div>
        <div className='mt-5 mb-5'>
          <div className='myNft'>
            {isConnected ? (
                isLoading ? (
                  <p>Loading...</p> // display loading message when fetching NFTs
                ) : nfts.length > 0 ? (
                  nfts.map(token => (
                    <div className='nft' key={token.id}>
                    <div className='nft-show'>
                      <div className='card-at'>
                        <div className='box-ct'>
                          <div className='box-side a1 light-scan-ct card-decoration'>
                            <i className='light-scan'></i>
                          </div>
                          <div className='box-side a2'></div>
                          <div className='box-side b1 card-decoration'></div>
                          <div className='box-side b2 card-decoration'></div>
                          <div className='box-side c1'></div>
                          <div className='box-side c2'></div>
                          <div className='card-inr'>
                            <div className='nft-card-ct'>
                              <img src={ipfsUrl(token.metadata.image)} alt={token.metadata.name} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='nft-info'>
                        <h5 className='mb-3'>{token.metadata.name}</h5>
                        {token.staked && (
                            <>
                                <p className='mb-0'>Reward: {Number(ethers.formatUnits(token.stakeInfo?.reward, 18)).toLocaleString("en-US") ?? 0} $ELMO</p>
                                <p>Burned: {Number(ethers.formatUnits(token.stakeInfo?.toBurn, 18)).toLocaleString("en-US") ?? 0} $ELMO</p>
                                {
                                  token.stakeInfo.startTime !== "0" && token.stakeInfo.startTime !== null &&
                                  <Countdown 
                                    date={moment(token.stakeInfo.startTime * 1000).add(stakePeriod, "seconds").toDate().getTime()} 
                                    renderer={renderer} 
                                    onComplete={e => { setCountDownCompleted(true) }} 
                                  />
                                }
                                {!countDownCompleted ?
                                  <></>
                                  : 
                                  <>
                                  <button className='minting__btn btn btn-primary' onClick={() => unstakeNFT(token.id)}>Claim</button>
                                  </>
                                }
                            </>
                        )}
  
                        {!token.staked && (
                          <>
                          {stakedStatuses[token.id] ? (
                            <p>This NFT can't be staked because it was already staked!</p>
                          ) : (
                            <>
                            <p>Choose burn % of your {Number(ethers.formatUnits(rewardsAmount, 18)).toLocaleString("en-US")} $ELMO reward.</p>
                            <div className='d-flex justify-content-between'>
                            <button 
                              className={`minting__btn btn btn-primary burn-btn ${selectedBurnPercents[token.id] === 40 ? 'active' : ''}`} 
                              onClick={() => handleBurnPercentSelection(token.id, 40)}
                            >
                              Burn 40%
                            </button>

                            <button 
                              className={`minting__btn btn btn-primary burn-btn ${selectedBurnPercents[token.id] === 60 ? 'active' : ''}`} 
                              onClick={() => handleBurnPercentSelection(token.id, 60)}
                            >
                              Burn 60%
                            </button>

                            <button 
                              className={`minting__btn btn btn-primary burn-btn ${selectedBurnPercents[token.id] === 80 ? 'active' : ''}`} 
                              onClick={() => handleBurnPercentSelection(token.id, 80)}
                            >
                              Burn 80%
                            </button>
                            </div>
                            {selectedBurnPercents[token.id] && (
                              <button 
                                className={`minting__btn btn btn-primary ${selectedBurnPercents[token.id]}`} 
                                onClick={() => stakeNFT(token.id, selectedBurnPercents[token.id])}
                              >
                                Stake this NFT
                              </button>
                            )}
                            </>
                          )}
                          </>
                        )}
                    </div>
                  </div>
                  ))
                ) : (
                  <p>You didn't mint any NFT yet.</p>
                )
              ) : (
                <>
                <div className="wallet-disconnect-prompt">
                  <p>Your wallet is disconnected. Please connect to view and interact with your NFTs.</p>
                </div>
                </>
              )
            }
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default StakeNftForm;
