//App.js
import { useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import StakeNftForm from "./components/StakeNftForm";
import { useAccount, useDisconnect } from 'wagmi';


function App() {
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()
  const myRef = useRef();

  useEffect(() => {
    if (!isConnected) {
      // Connection to the Ethereum provider is lost
      disconnect();
    }
  }, [address, isConnected, disconnect]);

  return (
    <>
    <Header />
    <section id="entire-staking-section" className="section section--pb0 section--first" style={{height: '100vh', overflowY: 'auto'}} ref={myRef}>
      <Container>
        <StakeNftForm />
      </Container>
    </section>
    </>
  );
}

export default App;
