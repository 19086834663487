//Header.js
import React, { useState } from 'react';
import logo from '../elmoerc.png';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__content">
                <button className={`header__btn ${isOpen ? 'header__btn--active' : ''}`} type="button" aria-label="header__nav" onClick={toggleMenu}>
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
                <div className="header__logo">
                    <img src={logo} alt="ElmoStake Logo" />
                    <ul className={`header__nav ${isOpen ? 'header__nav--active' : ''}`} id="header__nav">
                      <li>
                        <a href="https://www.elmoerc.io/">Home</a>
                      </li>
                      <li>
                        <a href="https://nfts.elmoerc.io/">Mint NFT</a>
                      </li>
                    </ul>
                </div>
                <ConnectButton chainStatus="none" showBalance={false} label='Connect' />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;